<template>
  <div id="index" ref="appRef" class="login-container">
    <div class="bg">
      <div class="main-body">
        <div class="login-form">
          <div class="title">登入</div>
          <form @submit.prevent="handleLogin">
            <input
              class="login-input"
              v-model="loginForm.username"
              type="text"
              placeholder="账号"
            />
            <input
              class="login-input"
              v-model="loginForm.password"
              type="password"
              placeholder="密码"
            />
            <!-- 记住账号密码 -->
            <div class="remember">
              <label>
                <input type="checkbox" v-model="isChecked" />
                记住账号密码
              </label>
            </div>
            <div class="error-msg" v-if="errorMessage !== ''">
              {{ errorMessage }}
            </div>
            <div class="btn-group">
              <button type="submit" class="login">登入</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginApi } from "@/api/modules/login";
import drawMixin from "../../utils/drawMixin";

export default {
  mixins: [drawMixin],
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      errorMessage: "",
      isChecked: false,
    };
  },
  mounted() {
    this.isChecked =
      localStorage.getItem("usernameCache") === "true" ? true : false;
    if (this.isChecked) {
      this.loginForm.username = localStorage.getItem("username");
      this.loginForm.password = localStorage.getItem("password");
    }
  },
  watch: {
    isChecked: {
      handler(val) {
        if (val === true) {
          localStorage.setItem("usernameCache", true);
          localStorage.setItem("username", this.loginForm.username);
          localStorage.setItem("password", this.loginForm.password);
        } else {
          localStorage.setItem("usernameCache", false);
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
      },
    },
    loginForm: {
      handler(val) {
        if (val && this.isChecked) {
          localStorage.setItem("username", val.username);
          localStorage.setItem("password", val.password);
        }
      },
      deep: true,
    },
  },
  methods: {
    async handleLogin() {
      try {
        const res = await loginApi(this.loginForm);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.user.userId);
        this.$router.push("/");
      } catch (error) {
        this.errorMessage = error.message;
        // console.log();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";

.main-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form {
  width: 350px;
  height: 400px;
  background-color: white;
  border-radius: 5px;
  color: black;
  padding: 75px 40px 0 40px;
  & .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  & .login-input {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 1rem;
  }
  & .remember {
    margin-bottom: 1rem;
    label {
      display: flex; /* 使用弹性盒子模型 */
      align-items: center; /* 垂直居中对齐 */
    }
    input[type="checkbox"] {
      margin-right: 10px; /* 调整右边距 */
    }
  }
}
.btn-group {
  button {
    cursor: pointer;
    width: 100%;
    background-color: aquamarine;
    text-align: center;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 18px;
    color: black;
  }
}
.error-msg {
  color: red;
  margin-bottom: 1rem;
}
</style>
